.footer {
    background-color: #1F1F23;
    padding: 40px 0px;
    color: #fff;
    ul {
        margin: 0px;
        padding: 0px;
        &.about-brand {
            li {
                display: flex;
                margin-bottom: 20px;
                .value {
                    font-weight: 700;
                }
            }
        }
        li {
            list-style: none;
            .icon {
                margin-right: 10px;
            }
        }
        &.link {
            li {
                width: 100%;
                margin-bottom: 20px;
                a {
                    color: #fff !important;
                }
            }
        }
        &.sosmed {
            li {
                display: inline-block;
                margin-right: 15px;
                img {
                    width: 25px;
                }
            }
        }
    }
    .desc {
        font-size: 14px;
        margin: 35px 0px;
    }
    .icon-brand {
        width: 150px;
    }
    .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 24px;
        // margin-top: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .footer ul.sosmed li {
        margin-top: 10px;
    }

    .footer {
        .wrapper-footer {
            padding: 0px 20px;
        }
    }
}