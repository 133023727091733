#detail-product {
  margin-bottom: 0px;
}
#detail-product #img-magnifer {
  width: 100%;
  display: flex;
  justify-content: center;
}
#detail-product .wrapper-content-product {
  padding: 0px 25px;
}
#detail-product .wrapper-content-product .name {
  font-size: 24px;
  color: var(--black);
  font-weight: 600;
  margin-bottom: 20px;
}
#detail-product .wrapper-content-product .number {
  font-size: 16px;
  text-transform: uppercase;
}
#detail-product .wrapper-content-product .line {
  margin: 0px 16px;
  color: #eceff1;
}
#detail-product .wrapper-content-product .text {
  color: var(--grey);
  font-size: 16px;
}
#detail-product .wrapper-content-product .sosmed ul {
  margin: 0px;
  padding: 0px;
}
#detail-product .wrapper-content-product .sosmed ul li {
  list-style: none;
  display: inline-block;
}
#detail-product .wrapper-content-product .sosmed ul li img {
  width: 24px;
}
#detail-product .wrapper-content-product .product-detail .item-detail {
  margin-bottom: 32px;
  align-items: center;
}
#detail-product .wrapper-content-product .product-detail .text {
  min-width: 150px;
  font-weight: 500;
  font-size: 16px;
  color: var(--black);
}
#detail-product .wrapper-content-product .product-detail .list-features {
  margin: 0px;
  padding-left: 15px;
}
#detail-product .wrapper-content-product .product-detail .list-features li {
  display: list-item;
}
#detail-product .wrapper-content-product .product-detail .value {
  color: #9a9a9a;
  font-size: 16px;
}
#detail-product .wrapper-content-product .product-detail .value ul li,
#detail-product .wrapper-content-product .product-detail .value ol li {
  display: list-item;
}
#detail-product .wrapper-content-product .product-detail .original-price {
  font-size: 14px;
  color: var(--grey);
  text-decoration: line-through;
}
#detail-product .wrapper-content-product .product-detail .discount {
  background-color: var(--red);
  color: #fff;
  font-weight: 500;
  padding: 4px;
  font-size: 13px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
#detail-product .wrapper-content-product .product-detail .discount-price {
  font-size: 24px;
  color: var(--red);
  font-weight: 600;
}
#detail-product .wrapper-content-product .product-detail .discount-price.black {
  color: var(--black);
}
#detail-product .wrapper-content-product .btn-white {
  width: 50%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
#detail-product .wrapper-content-product .wrapper-btn {
  width: 188px;
}
#detail-product .wrapper-content-product .wrapper-btn button {
  width: 100%;
}
#detail-product .wrapper-content-product .btn-black {
  width: 100%;
  border-radius: 4px;
}
#detail-product .main-image-product {
  width: 497px;
  height: 302px;
  -o-object-fit: contain;
     object-fit: contain;
}
#detail-product .list-detail-img .detail-img {
  border-radius: 16px;
  border: 1px solid #eceff1;
  padding: 15px;
  margin-right: 15px;
  cursor: pointer;
}
#detail-product .list-detail-img .detail-img img {
  width: 90px;
  height: 79px;
  -o-object-fit: contain;
     object-fit: contain;
}
#detail-product .btn-quantity {
  background-color: #f5f7fa;
  color: var(--black);
  font-size: 12px;
  width: 28px;
  height: 28px;
}
#detail-product .total-quantity {
  width: 52px;
  text-align: center;
}
#detail-product .wrapper-rating {
  min-width: 109px;
}
#detail-product .total-reviews {
  font-size: 16px;
  color: var(--grey);
}
#detail-product .star {
  width: 24px;
}
#detail-product .wrapper-review-bar {
  max-width: 500px;
  min-width: 400px;
  margin-left: 20px;
}
#detail-product .wrapper-review-bar .star {
  width: 15px;
  margin-right: 4px;
}
#detail-product .rating {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 3px;
}
#detail-product .review-bar {
  margin-bottom: 5px;
}
#detail-product .total-bar {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
}
#detail-product .wrapper-bar {
  background-color: #eceff1;
  height: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  width: 100%;
}
#detail-product .wrapper-bar .bar {
  background-color: #0ea0f2;
  height: 100%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
#detail-product .list-reviewer {
  margin-top: 50px;
}
#detail-product .list-reviewer .item-reviewer {
  margin-bottom: 35px;
  border-bottom: 1px solid #eceff1;
  padding-bottom: 30px;
}
#detail-product .list-reviewer .item-reviewer .img-reviewer {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
#detail-product .list-reviewer .item-reviewer .name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 3px;
}
#detail-product .list-reviewer .item-reviewer .date {
  font-size: 400;
  color: var(--grey);
  margin-left: 10px;
}
#detail-product .list-reviewer .item-reviewer .desc {
  font-size: 16px;
  color: var(--grey);
}

@media (max-width: 768px) {
  #detail-product .wrapper-content-product .product-detail .value {
    width: 100%;
  }
  #detail-product .wrapper-content-product .share {
    width: 100%;
  }
  #detail-product .main-image-product {
    width: 100%;
  }
  .wrapper-btn {
    margin-bottom: 20px;
  }
  .item-detail {
    flex-wrap: wrap;
  }
  .item-detail .text {
    width: 100%;
    margin-bottom: 10px;
  }
  .number {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .list-star {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .wrapper-btn {
    width: 100% !important;
  }
  .btn-black {
    margin-left: 0px !important;
    margin-top: 20px;
    width: 100% !important;
  }
  .line {
    display: none;
  }
  #detail-product .wrapper-review-bar {
    min-width: 100%;
  }
  .total-reviews {
    margin-bottom: 10px;
  }
  #detail-product .wrapper-content-product {
    padding: 0px;
  }
  #detail-product .main-image-product {
    height: 226px;
  }
  .wrapper-content-product {
    margin-top: 20px;
  }
  #detail-product .list-detail-img .detail-img img {
    width: 58px;
    height: 58px;
  }
}
.tab-content ul li,
.tab-content ol li {
  display: list-item;
}

figure.zoom {
  background-position: 50% 50%;
  position: relative;
  height: 413px;
  overflow: hidden;
  cursor: zoom-in;
  background-repeat: no-repeat;
}

figure.zoom img:hover {
  opacity: 0;
}

figure.zoom img {
  transition: opacity 0.5s;
  display: block;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  height: 100%;
}/*# sourceMappingURL=detail_product.css.map */