#list-product {
  margin-bottom: 0px;
}
#list-product .list-item .item {
  margin-right: 0px;
  margin-bottom: 40px;
  padding: 10px 13px;
}
#list-product .text {
  font-size: 12px;
  color: var(--grey);
}
#list-product .css-1fdsijx-ValueContainer {
  font-size: 12px;
}

.section-promo .btn-see-all {
  background-color: #0072ff;
  color: #fff;
  padding: 14px 30px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.section-promo .btn-see-all:hover {
  background-color: #0072ff;
  color: #fff;
}
.section-promo .content-wrapper {
  position: absolute;
  left: 50%;
  padding: 0px 50px;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.section-promo .content-wrapper .title-section {
  color: #fff;
  font-size: 35px;
}
.section-promo .content-wrapper .sub-title-section {
  color: #fff;
}
.section-promo .content-wrapper .btn-more {
  background-color: var(--main-color);
  color: #fff;
}
.section-promo .img-promo {
  width: 100%;
  height: 494px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.btn-filter {
  border: 1px solid var(--outline);
  font-weight: 500;
  font-size: 12px;
  padding: 8px 13px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.modal .list-filter {
  overflow-y: unset;
}

.modal-content {
  height: 500px;
  overflow: scroll;
  overflow-x: hidden;
}

.list-filter {
  padding-right: 15px;
}
.list-filter .title {
  color: #1f1f23;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 16px;
}
.list-filter .item-filter {
  margin-bottom: 10px;
  font-size: 14px;
}
.list-filter .total {
  border-radius: 4px;
  background: #eceff1;
  font-size: 14px;
  padding: 4px 8px;
}

/* width */
.list-filter::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.list-filter::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.list-filter::-webkit-scrollbar-thumb {
  background: #e2e2e2;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

/* Handle on hover */
.list-filter::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 768px) {
  .section-promo .img-promo {
    display: none;
  }
  .section-promo .btn-see-all {
    background-color: transparent;
    color: #1f1f23;
    margin-top: 0px !important;
  }
  .section-promo .content-wrapper {
    position: unset;
    transform: unset;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .section-promo .content-wrapper .title-section {
    color: var(--main-color);
    font-size: 20px;
    text-align: left !important;
  }
}/*# sourceMappingURL=list_product.css.map */