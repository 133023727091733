#list-product {
  margin-bottom: 0px;
  .list-item {
    .item {
      margin-right: 0px;
      margin-bottom: 40px;
      padding: 10px 13px;
    }
  }
  .text {
    font-size: 12px;
    color: var(--grey);
  }
  .css-1fdsijx-ValueContainer {
    font-size: 12px;
  }
}

.section-promo {
  .btn-see-all {
    background-color: #0072ff;
    color: #fff;
    padding: 14px 30px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    &:hover {
      background-color: #0072ff;
      color: #fff;
    }
  }
  .content-wrapper {
    position: absolute;
    left: 50%;
    padding: 0px 50px;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);

    .title-section {
      color: #fff;
      font-size: 35px;
    }
    .sub-title-section {
      color: #fff;
    }

    .btn-more {
      background-color: var(--main-color);
      color: #fff;
    }
  }
  .img-promo {
    width: 100%;
    height: 494px;
    object-fit: cover;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
}

.btn-filter {
  border: 1px solid var(--outline);
  font-weight: 500;
  font-size: 12px;
  padding: 8px 13px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.modal {
  .list-filter {
    overflow-y: unset;
  }
}
.modal-content {
  height: 500px;
  overflow: scroll;
  overflow-x: hidden;
}

.list-filter {
  // position: sticky;
  // top: 124px;
  // min-height: 607px;
  // max-height: 650px;
  // overflow-y: scroll;
  padding-right: 15px;
  .title {
    color: #1f1f23;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 16px;
  }
  .item-filter {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .total {
    border-radius: 4px;
    background: #eceff1;
    font-size: 14px;
    padding: 4px 8px;
  }
}

/* width */
.list-filter::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.list-filter::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.list-filter::-webkit-scrollbar-thumb {
  background: #e2e2e2;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

/* Handle on hover */
.list-filter::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 768px) {
  .section-promo {
    .img-promo {
      display: none;
    }
    .btn-see-all {
      background-color: transparent;
      color: #1f1f23;
      margin-top: 0px !important;
    }
    .content-wrapper {
      position: unset;
      transform: unset;
      -webkit-transform: unset;
      -moz-transform: unset;
      -ms-transform: unset;
      -o-transform: unset;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title-section {
        color: var(--main-color);
        font-size: 20px;
        text-align: left !important;
      }
    }
  }
}
