#detail-product {
  margin-bottom: 0px;

  #img-magnifer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .wrapper-content-product {
    padding: 0px 25px;
    .name {
      font-size: 24px;
      color: var(--black);
      font-weight: 600;
      margin-bottom: 20px;
    }
    .number {
      font-size: 16px;
      text-transform: uppercase;
    }
    .line {
      margin: 0px 16px;
      color: #eceff1;
    }
    .text {
      color: var(--grey);
      font-size: 16px;
    }
    .sosmed {
      ul {
        margin: 0px;
        padding: 0px;
        li {
          list-style: none;
          display: inline-block;
          img {
            width: 24px;
          }
        }
      }
    }

    .product-detail {
      .item-detail {
        margin-bottom: 32px;
        align-items: center;
      }
      .text {
        min-width: 150px;
        font-weight: 500;
        // font-family: "GeneralSans-Bold", sans-serif !important;
        font-size: 16px;
        color: var(--black);
      }

      .list-features {
        margin: 0px;
        padding-left: 15px;

        li {
          display: list-item;
        }
      }
      .value {
        color: #9a9a9a;
        font-size: 16px;

        ul,
        ol {
          li {
            display: list-item;
          }
        }
      }
      .original-price {
        font-size: 14px;
        color: var(--grey);
        text-decoration: line-through;
      }
      .discount {
        background-color: var(--red);
        color: #fff;
        font-weight: 500;
        padding: 4px;
        // margin-left: 12px;
        font-size: 13px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
      }
      .discount-price {
        font-size: 24px;
        color: var(--red);
        font-weight: 600;
        // margin-top: 10px;

        &.black {
          color: var(--black);
        }
      }
    }
    .btn-white {
      width: 50%;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
    }

    .wrapper-btn {
      width: 188px;
      button {
        width: 100%;
      }
    }
    .btn-black {
      width: 100%;
      border-radius: 4px;
      // margin-left: 16px;
    }
  }
  .main-image-product {
    width: 497px;
    height: 302px;
    object-fit: contain;
  }
  .list-detail-img {
    .detail-img {
      border-radius: 16px;
      border: 1px solid #eceff1;
      padding: 15px;
      margin-right: 15px;
      cursor: pointer;
      img {
        width: 90px;
        height: 79px;
        object-fit: contain;
      }
    }
  }
  .btn-quantity {
    background-color: #f5f7fa;
    color: var(--black);
    font-size: 12px;
    width: 28px;
    height: 28px;
  }
  .total-quantity {
    width: 52px;
    text-align: center;
  }

  .wrapper-rating {
    min-width: 109px;
  }
  .total-reviews {
    font-size: 16px;
    color: var(--grey);
    // margin-top: 10px;
  }

  .star {
    width: 24px;
  }
  .wrapper-review-bar {
    max-width: 500px;
    min-width: 400px;
    margin-left: 20px;
    .star {
      width: 15px;
      margin-right: 4px;
    }
  }

  .rating {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 3px;
  }
  .review-bar {
    margin-bottom: 5px;
  }
  .total-bar {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 500;
  }

  .wrapper-bar {
    background-color: #eceff1;
    height: 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    width: 100%;
    .bar {
      background-color: #0ea0f2;
      height: 100%;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }
  }

  .list-reviewer {
    margin-top: 50px;
    .item-reviewer {
      margin-bottom: 35px;
      border-bottom: 1px solid #eceff1;
      padding-bottom: 30px;
      .img-reviewer {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
      }
      .name {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 3px;
      }
      .date {
        font-size: 400;
        color: var(--grey);
        margin-left: 10px;
      }
      .desc {
        font-size: 16px;
        color: var(--grey);
      }
    }
  }
}

@media (max-width: 768px) {

  #detail-product .wrapper-content-product .product-detail .value {
    width: 100%;
  }

  #detail-product .wrapper-content-product .share {
    width: 100%;
  }

  #detail-product .main-image-product {
    width: 100%;
  }
  .wrapper-btn {
    margin-bottom: 20px;
  }

  .item-detail {
    flex-wrap: wrap;
    .text {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .number {
    // width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .list-star {
    // width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .wrapper-btn {
    width: 100% !important;
  }

  .btn-black {
    margin-left: 0px !important;
    margin-top: 20px;
    width: 100% !important;
  }

  .line {
    display: none;
  }

  #detail-product .wrapper-review-bar {
    min-width: 100%;
  }

  .total-reviews {
    margin-bottom: 10px;
  }

  #detail-product .wrapper-content-product {
    padding: 0px;
  }

  #detail-product .main-image-product {
    height: 226px;
  }

  .wrapper-content-product {
    margin-top: 20px;
  }

  #detail-product .list-detail-img .detail-img img {
    width: 58px;
    height: 58px;
  }
}

.tab-content {
  ul,
  ol {
    li {
      display: list-item;
    }
  }
}

figure.zoom {
  background-position: 50% 50%;
  position: relative;
  // width: 500px;
  height: 413px;
  overflow: hidden;
  cursor: zoom-in;
  background-repeat: no-repeat;
}
figure.zoom img:hover {
  opacity: 0;
}
figure.zoom img {
  transition: opacity 0.5s;
  display: block;
  width: 100%;
  object-fit: contain;
  height: 100%;
}
