.navbar {
  background-color: #fff;
  z-index: 1000;
  position: fixed !important;
  width: 100%;
  top: 0;
  transition: 0.3s ease-in-out;
  border-bottom: 1px solid #eceff1;
  display: block !important;
}
.navbar.menuscroll {
  box-shadow: 5px 12px 20px rgba(138, 138, 138, 0.13);
}
.navbar .btn-full-rounded {
  background-color: var(--main-color);
  color: #fff;
  font-weight: 500;
}
.navbar .navbar-brand,
.navbar .btn-rounded,
.navbar .navbar-collapse {
  width: 33.3333333333%;
}
.navbar .icon-brand {
  width: 120px;
  margin-right: 20px;
}
.navbar .btn-rounded-green:hover {
  background-color: #00a859;
}
.navbar .line {
  margin: 0px 20px;
  width: 1px;
  height: 20px;
  background-color: var(--outline);
}
.navbar .cart {
  border-radius: 8px;
  background: #f9f9f9;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.navbar .cart .amount-cart {
  position: absolute;
  background-color: #0ea0f2;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  width: 23px;
  height: 23px;
  line-height: 23px;
  text-align: center;
  border-radius: 17px;
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  -ms-border-radius: 17px;
  -o-border-radius: 17px;
  right: -10px;
  top: -10px;
}
.navbar .wrapper-profile {
  min-width: 188px;
  max-width: 400px;
  position: relative;
}
.navbar .wrapper-profile .dropdown-profile {
  position: absolute;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  top: 60px;
  width: 177px;
  overflow: hidden;
}
.navbar .wrapper-profile .dropdown-profile .menu-profile {
  display: flex;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
  color: var(--text);
}
.navbar .wrapper-profile .dropdown-profile .menu-profile:hover {
  background-color: #0ea0f2;
  color: #fff;
}
.navbar .wrapper-profile .dropdown-profile .menu-profile:hover svg path {
  stroke: #fff;
}
.navbar .wrapper-profile .dropdown-profile .menu-profile svg {
  margin-right: 12px;
}
.navbar .wrapper-profile .name {
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
}
.navbar .wrapper-profile .img-profile {
  margin-right: 10px;
}
.navbar .wrapper-profile .img-profile img {
  width: 42px;
  height: 42px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 42px;
  -webkit-border-radius: 42px;
  -moz-border-radius: 42px;
  -ms-border-radius: 42px;
  -o-border-radius: 42px;
}

ul li {
  display: inline-block;
}

.nav-link {
  font-size: 16px;
  padding: 0px;
  margin-left: 0px;
}
.nav-link:hover {
  color: var(--main-color);
  font-weight: 600;
}
.nav-link.active {
  color: var(--main-color) !important;
  font-weight: 600;
}

.wrapper-nav {
  display: none;
  z-index: 100;
  position: fixed;
  padding: 20px 0px;
  width: 100%;
  left: 0;
}
.wrapper-nav .wrapper-content-nav {
  background: #fff;
  width: 100%;
  position: absolute;
  left: 0;
  padding: 40px 0px;
}
.wrapper-nav .col-2 {
  padding: 0px;
}
.wrapper-nav .react-multi-carousel-dot-list {
  bottom: 0px !important;
}
.wrapper-nav .wrapper-item {
  margin-bottom: 40px;
  margin-top: 20px;
}
.wrapper-nav .item-brand .text {
  text-align: center;
  margin-top: 10px;
}
.wrapper-nav .item-brand img {
  width: 155px;
  height: 80px;
  -o-object-fit: contain;
     object-fit: contain;
}
.wrapper-nav .item-category .text {
  text-align: center;
  margin-top: 10px;
}
.wrapper-nav .item-category img {
  width: 200px;
  height: 80px;
  -o-object-fit: contain;
     object-fit: contain;
}

.wrapper-btn-header .btn-black,
.wrapper-btn-header .btn-white {
  width: 100px;
}

.input-search {
  height: 40px;
}

.nav-item-category:hover .nav-category {
  display: inline-block;
}

.nav-item-brand:hover .nav-brand {
  display: inline-block;
}

.nav-brand .item-brand {
  margin-right: 30px;
}

.nav-item-brand .wrapper-content-nav {
  padding: 0px;
}

.navigation-menu {
  display: flex;
  align-items: center;
  width: 100%;
}

.navigation-menu .input-group {
  width: 30%;
}

.navigation-menu.expanded {
  display: unset;
}
.navigation-menu.expanded .wrapper-navbar {
  display: block !important;
}
.navigation-menu.expanded .input-group {
  display: unset !important;
}

@media (max-width: 1024px) {
  #bt-contact {
    display: none;
  }
  .navbar .icon-brand {
    width: 74px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .wrapper-navbar {
    width: 100%;
    display: none;
    margin-top: 20px;
  }
  .input-group {
    display: none !important;
  }
  .navigation-menu.expanded .input-group {
    display: flex !important;
    order: 1;
    width: 100% !important;
  }
  .wrapper-nav .wrapper-content-nav {
    position: unset;
    padding: 0px;
  }
  .wrapper-nav {
    position: unset;
  }
  .navigation-menu.expanded {
    margin-top: 20px;
  }
  .nav-category .item-brand img {
    width: 100%;
    height: 63px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .navigation-menu.expanded {
    display: flex;
    flex-wrap: wrap;
  }
  .wrapper-navbar {
    order: 2;
    margin-top: 10px;
  }
  .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-lg,
  .navbar > .container-md,
  .navbar > .container-sm,
  .navbar > .container-xl,
  .navbar > .container-xxl {
    justify-content: space-between;
  }
}/*# sourceMappingURL=nav.css.map */