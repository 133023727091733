#list-blog {
  margin-bottom: 0px;
}
#list-blog .item-blog {
  min-height: 450px;
  max-height: 450px;
}
#list-blog .item-blog .img-blog {
  width: 100%;
  height: 252px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
#list-blog .item-blog .name {
  font-weight: 500;
  font-size: 16px;
  margin-top: 6px;
  color: var(--black);
}
#list-blog .item-blog .desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--grey);
  margin-top: 8px;
  font-size: 14px;
}
#list-blog .item-blog .category {
  color: var(--blue);
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}
#list-blog .item-blog .date {
  margin-top: 8px;
  font-size: 12px;
  color: var(--grey);
}

.filter .item-filter {
  color: var(--grey);
  font-size: 16px;
  margin-bottom: 8px;
  cursor: pointer;
}
.filter .item-filter:hover, .filter .item-filter.active {
  color: var(--black);
}
.filter .title-filter {
  font-weight: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

#detail-blog .category {
  color: var(--blue);
  font-size: 16px;
  font-weight: 500;
  margin-top: 3px;
}
#detail-blog .date {
  margin-top: 2px;
  font-size: 16px;
  color: var(--grey);
}
#detail-blog .img-blog {
  width: 100%;
  height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  margin-bottom: 20px;
}
#detail-blog .img-recent-post {
  width: 100px;
  height: 100px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 12px;
}
#detail-blog .name {
  font-weight: 500;
  font-size: 16px;
  color: var(--black);
}
#detail-blog .item-filter {
  margin-top: 16px;
}/*# sourceMappingURL=blog.css.map */