@import url("https://fonts.cdnfonts.com/css/general-sans");
// @font-face {
//   font-family: "GeneralSans-Bold";
//   src: url("../../font/GeneralSans-Bold.otf") format("(otf)");
//   font-display: swap;
//   font-weight: normal;
// }
// @font-face {
//   font-family: "GeneralSans-Medium";
//   font-display: auto;
//   src: url("../../font/GeneralSans-Medium.otf") format("(otf)");
//   font-display: swap;
//   font-weight: 500;
// }
// @font-face {
//   font-family: "GeneralSans-Regular";
//   font-display: auto;
//   src: url("../../font/GeneralSans-Regular.otf") format("(otf)");
//   font-display: swap;
//   font-weight: bold;
// }

.font-500 {
  font-family: "GeneralSans-Medium", sans-serif !important;
}
.font-600 {
  font-family: "GeneralSans-Bold", sans-serif !important;
}

:root {
  --black: #1f1f23;
  --grey: #9a9a9a;
  --red: #fc3434;
  --blue: #0ea0f2;
  --outline: #eceff1;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0px;
}

body {
  font-family: "General Sans", sans-serif !important;
}

.orange {
  color: #febb29 !important;
}

.btn-white {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid var(--black);
  padding: 10px 16px;
  text-decoration: none;
  color: var(--black);
  font-size: 16px;
  font-weight: 500;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.btn-black {
  background-color: var(--black);
  border-radius: 8px;
  border: 1px solid var(--black);
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;

  &:disabled {
    opacity: 0.5;
  }
}

.btn-blue {
  background-color: #0072ff;
  border-radius: 8px;
  padding: 10px 14px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.btn-green {
  background-color: #41b549;
  border-radius: 8px;
  padding: 10px 14px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.btn-orange {
  background-color: #fe5722;
  border-radius: 8px;
  padding: 10px 14px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.list-item {
  .item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    // margin: 0px 20px;
    margin-right: 20px;
    padding: 10px 20px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    &:hover {
      border: 1px solid #eceff1;
      .img-product {
        transform: scale(1.2);
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
      }
    }
    .discount {
      background-color: #fc3434;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      padding: 12px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
    .img-product {
      text-align: center;
      width: 200px;
      object-fit: contain;
      height: 200px;
      margin-top: 50px;
      transition: transform 0.5s ease;
      -webkit-transition: transform 0.5s ease;
      -moz-transition: transform 0.5s ease;
      -ms-transition: transform 0.5s ease;
      -o-transition: transform 0.5s ease;
    }
    .text-product {
      margin-top: 60px;
      .rating {
        color: #9a9a9a;
        font-size: 14px;
      }
      .category {
        color: #0ea0f2;
        font-weight: 400;
        font-size: 400;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }
      .name {
        font-size: 16px;
        font-weight: 700;
        color: var(--black);
        margin-top: 6px;
        height: 54px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
      }
      .star {
        margin-right: 6px;
        // margin-top: 6px;
      }
      .line {
        color: #9a9a9a;
        font-size: 14px;
        margin-left: 5px;
      }
      .total-review {
        color: #9a9a9a;
        font-size: 14px;
        margin-left: 5px;
        // margin-top: 6px;
      }
      .original-price {
        color: #9a9a9a;
        font-size: 12px;
        font-weight: 600;
        margin-right: 5px;
        text-decoration: line-through;
      }
      .discount-price {
        color: #c02626;
        font-size: 20px;
        font-weight: 600;
        &.black {
          color: var(--black);
        }
      }
      .price {
        margin-top: 20px;
      }
    }
  }
}

.see-all {
  color: #0ea0f2;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}
.title-section {
  font-size: 24px;
  font-weight: 600;
}

.section {
  margin-top: 161px;
}

label > input[type="radio"],
label > input[type="radio"] {
  display: none !important;
}

label > input[type="radio"] + *::before,
label > input[type="radio"] + *::before {
  content: "" !important;
  display: inline-block !important;
  vertical-align: bottom !important;
  width: 1.1rem !important;
  height: 1.1rem !important;
  margin-right: 8px !important;
  border-radius: 50% !important;
  border-style: solid;
  border-width: 0.1rem;
  border-color: #9a9a9a !important;
}

// label>input[type="radio"]:checked+*,
// label>input[type="radio"]:checked+* {
//     color: #26B2C3 !important;
// }

label > input[type="radio"]:checked + *::before,
label > input[type="radio"]:checked + *::before {
  background: radial-gradient(
    #0ea0f2 0%,
    #0ea0f2 40%,
    transparent 50%,
    transparent
  ) !important;
  border-color: #0ea0f2 !important;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  margin-right: 10px;
  font: inherit;
  color: currentColor;
  width: 22px;
  height: 22px;
  border: 1px solid #d0d0d0;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  padding: 0px !important;
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #fff;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:checked {
  background-color: #0ea0f2 !important;
  border: #0ea0f2 solid 2px;
}

a {
  text-decoration: none !important;
}
.breadcrumb-item {
  a {
    color: #9a9a9a;
  }
  &.active {
    color: #1f1f23 !important;
    font-weight: 500;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b7b7b7;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.nav-tabs .nav-item .nav-link.active {
  border-bottom: 2px solid var(--blue) !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 0px;
  color: var(--blue) !important;
  font-weight: 600;
  font-size: 16px;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}
.nav-tabs .nav-link {
  color: var(--grey);
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-right: 0px !important;
  border-top: 0px !important;
  border-left: 0px !important;
  color: var(--blue) !important;
}

.react-multi-carousel-dot-list {
  bottom: 20px;
}

table tr td {
  vertical-align: middle;
}

table tr th {
  font-weight: 500;
  font-size: 16px;
}

table tr td {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.modal-backdrop {
  opacity: 0.5;
}

.modal-content {
  overflow: hidden !important;
}
.close-modal {
  position: absolute;
  right: 10px;
  top: 2%;
  cursor: pointer;
  z-index: 100;
}

.pointer {
  cursor: pointer;
}

.modal-title {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px !important;
}

.modal-body {
  padding: 25px 25px !important;
  overflow: auto;
}

.form-group label {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
  color: var(--text);
}

.form-group .form-control {
  color: var(--text);
  /* border-radius: 4px; */
  border: 1px solid #c4c4c4;
  padding: 13px 16px;
}

.form-group .css-13cymwt-control {
  color: var(--text);
  /* border-radius: 4px; */
  border: 1px solid #c4c4c4;
  padding: 5px 10px;
}

.css-t3ipsp-control {
  padding: 5px 10px;
}

.summary-total {
  margin-bottom: 12px;
  .text {
    min-width: 100px;
    text-align: left;
    font-size: 16px;
    &.total {
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.video-responsive {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  iframe {
    height: 680px;
  }
}

@media (max-width: 768px) {
  .list-item .item .text-product .line {
    display: block;
  }
  .section {
    margin-top: 100px;
  }

  .list-item .item .img-product {
    width: 176px;
    height: 137px;
  }
  .list-item .item .text-product .category {
    font-size: 12px;
  }
  .list-item .item .text-product .name {
    font-size: 14px;
    height: 45px;
  }
  .list-item .item .text-product .price {
    margin-top: 15px;
  }
  .list-item .item .text-product .discount-price {
    font-size: 16px;
  }

  .breadcrumb-item {
    font-size: 12px;
  }
}

// .css-1fdsijx-ValueContainer {
//   padding: 0px;
// }

// #img-magnifer {
//   img {
//     height: 362px !important;
//   }
// }

.react-multiple-carousel__arrow--left {
  left: 0;
  z-index: 900;
}
.react-multiple-carousel__arrow--right {
  right: 0;
  z-index: 900;
}

.react-multiple-carousel__arrow {
  background: rgb(81 81 81 / 50%);
}