.section {
  padding-bottom: 100px;
}

.name-product {
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
}

.amount {
  margin-left: 20px;
  font-size: 16px;
}

#cart {
  .img-product {
    width: 100px;
    height: 62px;
    object-fit: contain;
  }
  .btn-quantity {
    background-color: #f5f7fa;
    color: var(--black);
    font-size: 12px;
    width: 28px;
    height: 28px;
  }
  .total-quantity {
    width: 52px;
    text-align: center;
  }

  .btn-black {
    margin-top: 32px;
  }

  .icon-trash {
    cursor: pointer;
  }
}

.card-checkout {
  .title-card {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 21px;
  }
  .shipping-address {
    .name {
      font-size: 16px;
      font-weight: 500;
    }

    .text {
      color: #706c7a;
      font-size: 16px;
    }

    .label {
      background-color: #dcf4ff;
      padding: 4px 10px;
      color: #0ea0f2;
      font-size: 12px;
      margin-left: 8px;
      font-weight: 600;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
    }
  }
}

.order-summary {
  padding: 16px 12px;
  border: 1px solid var(--outline);

  .title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .text-summary {
    color: var(--grey);
    margin-bottom: 8px;
  }
  hr {
    margin: 10px 0px;
  }
  .total-summary {
    font-weight: 500;
    font-size: 16px;
    margin-top: 8px;
  }
}

.item-method {
  width: 360px;
}

.price-shipping-method {
  font-size: 16px;
  font-weight: 500;
}

textarea {
  height: 100px;
}

#payment {
  .img-product {
    width: 100px;
    height: 62px;
    object-fit: contain;
  }
  .btn-black {
    min-width: 283px;
  }
  .pay-before {
    color: #c02626;
    font-size: 16px;
    font-weight: 500;
  }

  .no-invoice {
    font-size: 16px;
    font-weight: 500;
  }

  .wrapper-detail-shipping {
    padding: 16px;
    border: 1px solid var(--outline);
    margin-top: 20px;
    .detail-shipping {
      margin-bottom: 10px;
      .value {
        font-size: 32px;
        font-weight: 500;
      }
    }
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }

  .summary-total {
    margin-bottom: 12px;
    .text {
      min-width: 100px;
      text-align: left;
      font-size: 16px;
      &.total {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}
