.hero {
  position: relative;
  height: 100vh;
}
.hero .img-slideshow {
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
}
.hero .wrapper-text {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  width: 100%;
}
.hero .wrapper-text .title-hero {
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 20px;
}
.hero .wrapper-text .info-hero {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

#category {
  margin-top: 90px;
}
#category .list-item:hover > .item-category:not(:hover) .text-product {
  display: none;
}
#category .list-item:hover > .item-category:not(:hover).active {
  border: none;
}
#category .list-item:hover > .item-category:hover {
  border: 1px solid var(--blue);
}
#category .item-category {
  min-width: 10%;
  padding: 40px 40px;
  border: 1px solid #eceff1;
}
#category .item-category:hover .text-product {
  display: block;
}
#category .item-category.active {
  border: 1px solid var(--blue);
}
#category .item-category.active .text-product {
  display: block;
}
#category .item-category .text-product {
  display: none;
  margin-left: 20px;
}
#category .item-category .img-product {
  width: 115px;
  -o-object-fit: contain;
     object-fit: contain;
}
#category .item-category .best {
  font-size: 12px;
  margin-bottom: 5px;
}
#category .item-category .title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
#category .item-category .view-all {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}
#category .item-category .view-all:hover .icon-arrow path {
  stroke: #fff;
}
#category .item-category .view-all:hover .icon-arrow circle {
  fill: #0ea0f2;
  stroke: #0ea0f2;
}
#category .item-category .view-all .icon-arrow {
  margin-left: 10px;
}

#best-deal {
  margin-top: 80px;
  margin-bottom: 80px;
}

.maps {
  position: relative;
  height: 100vh;
}
.maps iframe {
  position: relative;
  width: 100%;
  height: 100%;
}

#news {
  margin-bottom: 80px;
}
#news .item-news {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin: 0px;
  margin-bottom: 20px;
}
#news .item-news:hover img {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
#news .item-news .info-item {
  position: absolute;
  left: 20px;
  bottom: 20px;
  color: #fff;
  z-index: 3;
}
#news .item-news .info-item .title {
  font-size: 16px;
  font-weight: 500;
}
#news .item-news .info-item .date {
  font-size: 12px;
}
#news .item-news .category {
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 28px;
  background: rgba(255, 255, 255, 0.67);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  padding: 12px 16px;
  z-index: 3;
  font-weight: 500;
}
#news .item-news .see-all {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 28px;
  background: #fff;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  padding: 12px 16px;
  z-index: 3;
  font-size: 16px;
  color: #1f1f23;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
#news .item-news img {
  width: 100%;
  height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transition: transform 0.5s ease;
}

#brand {
  margin-bottom: 80px;
}
#brand .img-brand {
  width: 200px;
  height: 100px;
  aspect-ratio: 2/3;
  -o-object-fit: contain;
     object-fit: contain;
}

#best-offers {
  margin-bottom: 80px;
}
#best-offers .list-category {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}
#best-offers .item-category {
  padding: 20px 12px;
  color: #9a9a9a;
  font-size: 20px;
  border-bottom: 1px solid var(--outline);
  cursor: pointer;
  position: relative;
  text-align: center;
  min-width: 150px;
}
#best-offers .item-category .title {
  margin-top: 15px;
}
#best-offers .item-category img {
  width: 50px;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-right: 20px;
  margin-left: 20px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
#best-offers .item-category.active, #best-offers .item-category:hover {
  background: #f9f9f9;
  color: #1f1f23;
  border-bottom: none;
}
#best-offers .item-category.active::before, #best-offers .item-category:hover::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  background-color: var(--blue);
  height: 2px;
}
#best-offers .item-category.active svg path, #best-offers .item-category:hover svg path {
  stroke: #0ea0f2;
}
#best-offers .item-category.active svg circle, #best-offers .item-category:hover svg circle {
  stroke: #0ea0f2;
}
#best-offers .item-category.active svg rect, #best-offers .item-category:hover svg rect {
  stroke: #0ea0f2;
}
#best-offers .item-category.active .title, #best-offers .item-category:hover .title {
  font-weight: 600;
}
#best-offers .item-category svg {
  margin-right: 20px;
}

.section-information .img-about {
  height: 300px;
  -o-object-fit: contain;
     object-fit: contain;
}
.section-information .nav-information .item-nav {
  font-size: 16px;
  margin-bottom: 16px;
  color: #9a9a9a;
}
.section-information .nav-information .item-nav.terms-condition {
  width: 200px;
}
.section-information .nav-information .item-nav.active {
  color: var(--blue);
  font-weight: 600;
}
.section-information .sub-title {
  font-size: 16px;
  color: #0ea0f2;
  font-weight: 500;
}
.section-information .img-brand {
  padding: 0px 20px;
  width: 100%;
  height: 100px;
  aspect-ratio: 2/3;
  -o-object-fit: contain;
     object-fit: contain;
}
.section-information .btn-black {
  padding: 13px 24px;
  border-radius: 5px;
}
.section-information .card-contact {
  padding: 25px;
  color: #fff;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  position: relative;
}
.section-information .card-contact .img-contact {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  height: 500px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.section-information .card-contact .item-info {
  margin-bottom: 20px;
}
.section-information .card-contact .item-info span {
  font-weight: 600;
}
.section-information .card-contact .title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 32px;
}
.section-information .card-contact .title-item {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}
.section-information .card-contact svg {
  margin-right: 10px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  #category .list-item {
    flex-wrap: wrap;
  }
  #category .text-product {
    display: block !important;
  }
  #news .item-news .see-all {
    text-align: center;
    width: 60%;
  }
}
@media only screen and (max-width: 768px) {
  #category .item-category .title {
    height: 70px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
  .nav-information::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none !important;
  }
  .section-information .nav-information {
    overflow-y: hidden;
    overflow-x: scroll;
    margin-bottom: 20px;
  }
  .section-information .nav-information .item-nav {
    min-width: 139px;
  }
  #category .list-item {
    flex-wrap: wrap;
  }
  #category .item-category {
    flex-wrap: wrap;
  }
  #category .item-category .text-product {
    display: block !important;
    width: 100%;
    margin-left: 0;
  }
  #best-offers .list-item {
    margin-top: 20px;
  }
  #best-offers .item-category img {
    width: 86px;
  }
  .hero .wrapper-text .title-hero {
    font-size: 25px;
    line-height: 30px;
  }
  .hero .wrapper-text {
    width: 100%;
    padding: 0px 20px;
  }
  .hero .wrapper-text .info-hero {
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
  }
  #brand .item-brand {
    display: flex;
    justify-content: center;
  }
  #category .item-category .img-product {
    margin-bottom: 20px;
    width: 141px;
  }
  #category .item-category.active .text-product {
    margin-left: 0px;
  }
}/*# sourceMappingURL=home.css.map */