.account .title-card {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.account .btn-black, .account .btn-white {
  width: 200px;
  padding: 14px;
}
.account .img-profile {
  width: 78px;
  height: 78px;
  border-radius: 78px;
  -webkit-border-radius: 78px;
  -moz-border-radius: 78px;
  -ms-border-radius: 78px;
  -o-border-radius: 78px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 16px;
}
.account .wrapper-nav-left {
  border-radius: 8px;
  border: 1px solid var(--outline);
  padding: 20px;
}
.account .wrapper-nav-left .item-nav {
  padding: 12px 16px;
  margin-bottom: 10px;
  color: var(--black);
  position: relative;
}
.account .wrapper-nav-left .item-nav.active, .account .wrapper-nav-left .item-nav:hover {
  background: #ecf8ff;
  color: #0ea0f2;
  font-weight: 500;
}
.account .wrapper-nav-left .item-nav.active::after, .account .wrapper-nav-left .item-nav:hover::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 4px;
  background-color: rgba(14, 160, 242, 0.8823529412);
  height: 100%;
}

#transaction .title {
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
}
#transaction .img-product {
  width: 100px;
  height: 62px;
  -o-object-fit: contain;
     object-fit: contain;
}
#transaction .total-quantity {
  width: 52px;
  text-align: center;
}
#transaction .icon-trash {
  cursor: pointer;
}
#transaction .deadline-payment {
  border-radius: 8px;
  border: 2px solid #ff3d3d;
  padding: 20px;
  background: #fff6f6;
}
#transaction .time {
  color: #fff;
  background-color: #ff3d3d;
  font-size: 16px;
  font-weight: 500;
  padding: 6px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
#transaction .colon {
  color: #ff3d3d;
  font-size: 16px;
  padding: 6px;
}
#transaction .card-transaction {
  padding: 20px;
  border: 1px solid var(--outline);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
#transaction .card-transaction .shipping-address {
  max-width: 500px;
}
#transaction .line {
  width: 1px;
  height: 40px;
  background-color: var(--outline);
}
#transaction .value {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 16px;
}
#transaction .text {
  margin-bottom: 5px;
}
#transaction .summary-total {
  margin-bottom: 0px;
}

#address .card-address {
  border: 1px solid var(--outline);
  border-radius: 8px;
  padding: 16px;
}
#address .shipping-address .name {
  font-size: 16px;
  font-weight: 500;
}
#address .shipping-address .text {
  color: #706c7a;
  font-size: 16px;
}
#address .shipping-address .label {
  background-color: #dcf4ff;
  padding: 4px 10px;
  color: #0ea0f2;
  font-size: 12px;
  margin-left: 8px;
  font-weight: 600;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}/*# sourceMappingURL=account.css.map */