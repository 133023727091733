.footer {
  background-color: #1F1F23;
  padding: 40px 0px;
  color: #fff;
}
.footer ul {
  margin: 0px;
  padding: 0px;
}
.footer ul.about-brand li {
  display: flex;
  margin-bottom: 20px;
}
.footer ul.about-brand li .value {
  font-weight: 700;
}
.footer ul li {
  list-style: none;
}
.footer ul li .icon {
  margin-right: 10px;
}
.footer ul.link li {
  width: 100%;
  margin-bottom: 20px;
}
.footer ul.link li a {
  color: #fff !important;
}
.footer ul.sosmed li {
  display: inline-block;
  margin-right: 15px;
}
.footer ul.sosmed li img {
  width: 25px;
}
.footer .desc {
  font-size: 14px;
  margin: 35px 0px;
}
.footer .icon-brand {
  width: 150px;
}
.footer .title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
}

@media only screen and (max-width: 768px) {
  .footer ul.sosmed li {
    margin-top: 10px;
  }
  .footer .wrapper-footer {
    padding: 0px 20px;
  }
}/*# sourceMappingURL=footer.css.map */