#list-blog {
  margin-bottom: 0px;
  .item-blog {
    min-height: 450px;
    max-height: 450px;

    .img-blog {
      width: 100%;
      height: 252px;
      object-fit: cover;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
    }
    .name {
      font-weight: 500;
      font-size: 16px;
      margin-top: 6px;
      color: var(--black);
    }
    .desc {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: var(--grey);
      margin-top: 8px;
      font-size: 14px;
    }
    .category {
      color: var(--blue);
      font-size: 16px;
      font-weight: 500;
      margin-top: 10px;
    }
    .date {
      margin-top: 8px;
      font-size: 12px;
      color: var(--grey);
    }
  }
  
}
.filter {
  .item-filter {
    color: var(--grey);
    font-size: 16px;
    margin-bottom: 8px;
    cursor: pointer;

    &:hover,&.active{
      color: var(--black);
    }
  }
  .title-filter {
    font-weight: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

#detail-blog {
  .category {
    color: var(--blue);
    font-size: 16px;
    font-weight: 500;
    margin-top: 3px;
  }
  .date {
    margin-top: 2px;
    font-size: 16px;
    color: var(--grey);
  }
  .img-blog {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    margin-bottom: 20px;
  }
  .img-recent-post {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 12px;
  }
  .name {
    font-weight: 500;
    font-size: 16px;
    color: var(--black);
  }
  .item-filter {
    margin-top: 16px;
  }
}


@media (max-width: 768px) {


}