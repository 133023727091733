/* CSS only for examples not required for centering */

.login {
  a {
    color: #1f1f23 !important;
  }
  overflow-x: hidden;
  .logo-brand {
    img {
      width: 150px;
    }
  }
}

.login .title-form {
  margin-top: 35px;
}

.title-form .welcome-text {
  font-size: 22px;
  color: var(--text);
  font-weight: 700;
}

.title-form .desc-text {
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  color: var(--dashboard-placeholder, #898989);
}



.login .img-logo {
  margin-top: 20px;
}

.login .logo {
  width: 200px;
}

.text-forgot-password {
  text-decoration: underline;
  color: var(--primary);
}

.login .banner .screen-img {
  width: 300px;
  height: 300px;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 2;
}

.login .content {
  padding: 38px 20px;
}

.login .d-table {
  padding: 0px !important;
}


.login .btn:hover {
  color: #fff !important;
}

.form-group {
  position: relative;
}

.login .form-group label {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
  color: var(--text);
}

.login .form-group .form-control {
  color: var(--text);
  /* border-radius: 4px; */
  border: 1px solid #c4c4c4;
  padding: 13px 16px;
}

.form-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}

.btn-form {
  background-color: var(--primary);
  width: 100%;
  color: #fff;
  font-size: 16px;
  padding: 10px 10px;
  border-radius: 0px;
}

.text-link a {
  text-decoration: underline;
  font-size: 14px;
}

.text-link {
  font-size: 14px;
}

/* Modal */

.modal-email .btn {
  background-color: #32cfb0;
  color: white;
  padding: 5px 20px;
}

.modal-email {
  padding: 0px 50px;
}

.modal-email span {
  color: #adadad;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 300;
}

.modal-email svg {
  width: 60px;
  margin: auto;
}

.login .card {
  background: transparent;
  box-shadow: none;
  border: none;
}

.input-group-text {
  background-color: #fff;
  border-right: none;
  border-color: #c4c4c4;
}

.img-banner {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 20px;
}

// .icon {
//   position: absolute;
//   top: 50%;
//   right: 2%;
//   width: 20px;
//   transform: translate(-50%, -50%);
//   -webkit-transform: translate(-50%, -50%);
//   -moz-transform: translate(-50%, -50%);
//   -ms-transform: translate(-50%, -50%);
//   -o-transform: translate(-50%, -50%);
//   cursor: pointer;
// }
.text-banner {
  color: #fff;
  position: absolute;
  top: 102px;
  left: 82px;
  .title {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .sub-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
  }
}

/* Register */

.register .banner .screen-img {
  width: 400px;
  height: 400px;
}

/* Media Query */
@media (min-width: 768px) and (max-width: 900px) {
  .login .content {
    padding: 0px 90px;
  }
}

@media (max-width: 768px) {
  .login .col-banner {
    display: none !important;
  }

  .login .col-content {
    background-color: #32d2a2;
  }

  .login .content {
    padding: 0px 10px;
  }

  .login .card {
    margin: 60px !important;
    padding: 20px !important;
  }
}

@media (max-width: 467px) {
  .forgot-password .login-title h3 {
    font-size: 19px;
  }
}
