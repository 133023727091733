.hero {
  position: relative;
  height: 100vh;
  .img-slideshow {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  .wrapper-text {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    width: 100%;
    .title-hero {
      font-size: 40px;
      font-weight: 600;
      line-height: 40px;
      margin-bottom: 20px;
    }
    .info-hero {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      max-width: 50%;
      margin: 0 auto;
    }
  }
}

#category {
  margin-top: 90px;
  .list-item:hover > .item-category:not(:hover) {
    .text-product {
      display: none;
    }
    &.active {
      border: none;
    }
  }
  .list-item:hover > .item-category:hover {
    border: 1px solid var(--blue);
  }

  .item-category {
    min-width: 10%;
    padding: 40px 40px;
    border: 1px solid #eceff1;
    &:hover {
      .text-product {
        display: block;
      }
    }
    &.active {
      border: 1px solid var(--blue);
      .text-product {
        display: block;
      }
    }

    .text-product {
      display: none;
      margin-left: 20px;
    }
    .img-product {
      width: 115px;
      object-fit: contain;
    }
    .best {
      font-size: 12px;
      margin-bottom: 5px;
    }
    .title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .view-all {
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        .icon-arrow {
          path {
            stroke: #fff;
          }
          circle {
            fill: #0ea0f2;
            stroke: #0ea0f2;
          }
        }
      }
      .icon-arrow {
        margin-left: 10px;
      }
    }
  }
}

#best-deal {
  margin-top: 80px;
  margin-bottom: 80px;
}

.maps {
  position: relative;
  // top: -1px;
  height: 100vh;

  iframe {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

#news {
  margin-bottom: 80px;
  .item-news {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    margin: 0px;
    margin-bottom: 20px;
    &:hover {
      img {
        transform: scale(1.2);
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
      }
    }

    .info-item {
      position: absolute;
      left: 20px;
      bottom: 20px;
      color: #fff;
      z-index: 3;
      .title {
        font-size: 16px;
        font-weight: 500;
      }
      .date {
        font-size: 12px;
      }
    }
    .category {
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 28px;
      background: rgba(255, 255, 255, 0.67);
      backdrop-filter: blur(2px);
      padding: 12px 16px;
      z-index: 3;
      font-weight: 500;
    }
    .see-all {
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 28px;
      background: #fff;
      backdrop-filter: blur(2px);
      padding: 12px 16px;
      z-index: 3;
      font-size: 16px;
      color: #1f1f23;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
    }
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      z-index: -1;
      transition: transform 0.5s ease;
    }
  }
}

#brand {
  margin-bottom: 80px;
  .img-brand {
    width: 200px;
    height: 100px;
    aspect-ratio: 2/3;
    object-fit: contain;
  }
}

#best-offers {
  margin-bottom: 80px;
  .list-category {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
  }
  .item-category {
    padding: 20px 12px;
    color: #9a9a9a;
    font-size: 20px;
    border-bottom: 1px solid var(--outline);
    cursor: pointer;
    position: relative;
    text-align: center;
    min-width: 150px;
    .title {
      margin-top: 15px;
    }
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
      margin-right: 20px;
      margin-left: 20px;
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
    }

    &.active,
    &:hover {
      background: #f9f9f9;
      color: #1f1f23;
      border-bottom: none;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        display: block;
        width: 100%;
        background-color: var(--blue);
        height: 2px;
      }
      // img {
      //   transform: scale(1.4, 1.4);
      //   -ms-transform: scale(1.4, 1.4);
      //   -webkit-transform: scale(1.4, 1.4);
      //   -moz-transform: scale(1.4, 1.4);
      //   -o-transform: scale(1.4, 1.4);
      //   filter: grayscale(0%);
      //   -webkit-filter: grayscale(0%);
      // }

      svg {
        path {
          stroke: #0ea0f2;
        }
        circle {
          stroke: #0ea0f2;
        }
        rect {
          stroke: #0ea0f2;
        }
      }

      .title {
        font-weight: 600;
      }
    }
    svg {
      margin-right: 20px;
    }
  }
}

.section-information {
  .img-about {
    height: 300px;
    object-fit: contain;
  }

  .nav-information {
    .item-nav {
      font-size: 16px;
      margin-bottom: 16px;
      color: #9a9a9a;

      &.terms-condition {
        width: 200px;
      }
      &.active {
        color: var(--blue);
        font-weight: 600;
      }
    }
  }
  .sub-title {
    font-size: 16px;
    color: #0ea0f2;
    font-weight: 500;
  }
  .img-brand {
    padding: 0px 20px;
    width: 100%;
    // width: 150px;
    height: 100px;
    aspect-ratio: 2/3;
    object-fit: contain;
  }
  .btn-black {
    padding: 13px 24px;
    border-radius: 5px;
  }
  .card-contact {
    padding: 25px;
    color: #fff;
    // background-image: url("../../img/img-contact-us.png");
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    position: relative;

    .img-contact {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      height: 500px;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
    }
    .item-info {
      margin-bottom: 20px;
      span {
        font-weight: 600;
      }
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 32px;
    }
    .title-item {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    svg {
      margin-right: 10px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  #category {
    .list-item {
      flex-wrap: wrap;
    }
    .text-product {
      display: block !important;
    }
  }

  #news .item-news .see-all {
    text-align: center;
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  #category .item-category .title {
    height: 70px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
  .nav-information::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none !important;
  }
  .section-information {
    .nav-information {
      overflow-y: hidden;
      overflow-x: scroll;
      margin-bottom: 20px;

      .item-nav {
        // text-align: center;
        min-width: 139px;
      }
    }
  }
  // .navbar {
  //   display: none !important;
  // }
  #category {
    .list-item {
      flex-wrap: wrap;
    }
    .item-category {
      flex-wrap: wrap;
      .text-product {
        display: block !important;
        width: 100%;
        margin-left: 0;
      }
    }
  }
  #best-offers {
    .list-item {
      margin-top: 20px;
    }
    .item-category {
      img {
        width: 86px;
      }
    }
  }

  .hero .wrapper-text .title-hero {
    font-size: 25px;
    line-height: 30px;
  }
  .hero .wrapper-text {
    width: 100%;
    padding: 0px 20px;
  }
  .hero .wrapper-text .info-hero {
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    max-width: 65%;
  }
  #brand {
    .item-brand {
      display: flex;
      justify-content: center;
    }
  }

  #category .item-category .img-product {
    margin-bottom: 20px;
    width: 141px;
  }
  #category .item-category.active .text-product {
    margin-left: 0px;
  }
}
